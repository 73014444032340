.sidebar-logo img {
    width: 120px !important;
    height: 30px;
}

.sidebar-foldername,
a.sidebar-item,
.sidebar-subitem {
    font-size: 15px;
    font-weight: 300;
    color: #bbb;
}

.sidebar-menu hr {
    border-color: #999;
    margin: 8px 0;
}

#app-container[data-page='/page/DashboardCustomerOrder'] h3:nth-child(1) {
    color: red;
}

#app-container[data-page='/page/DashboardSupplierOrder'] h3:nth-child(1) {
    color: blue;
}


#app-container[data-page='/page/ShippingDashboard'] h3:nth-child(1) {
    color: green;
}

#modal-details-gyv_customer_offer tr[data-name='modal-details-field-id'] {
    display: none;
}

#modal-details-gyv_customer_order tr[data-name='modal-details-field-id'] {
    display: none;
}

#modal-details-gyv_supplier_order tr[data-name='modal-details-field-id'] {
    display: none;
}



.custom-accordion .accordion-button {
    background-color: #f2f2f2; 
}

#activity_field_idGoogleCalendar{
    display: none;
}
#supplier_order_field_status .form-select{
    pointer-events: none;
    background-color: lightgray;
}
/* #customer_offer_field_status .form-select{
    pointer-events: none;
    background-color: lightgray;
} */
#customer_offer_field_vat{
    display: none;
} 
#customer_order_field_vat{
    display: none;
} 
#customer_field_type .form-select{
    pointer-events: none;
    background-color: lightgray;
}

#customer_order_field_supplier_orders{
    display: none;
}
#customer_order_payment_field_idCustomer input{
    pointer-events: none;
    background-color: lightgray;
}
#customer_order_payment_field_idCustomer button{
    pointer-events: none;
}
#customer_order_payment_field_idCustomer{
    pointer-events: none;
}
#customer_order_payment_field_idCustomer span{
    background-color: lightgray !important;
    color: black;
}
#customer_order_field_longitude{
    display: none;
}
#customer_order_field_latitude{
    display: none;
}


@media print {
    .print-header {
        display: table-header-group;
    }
}
